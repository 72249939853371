<template>
  <div class="ma-0 pa-0">

    <!-- Snackbar d'erreur -->
    <v-snackbar v-model="snackErreur" color="red">
      {{snackMessage}}
      <v-btn text color="black" @click.native="snackErreur = false">Close</v-btn>
    </v-snackbar>

    <!-- Snackbar après copie dans le presse-papiers -->
    <v-snackbar v-model="snackClipbaord" color="green text--white" timeout="2000">
      Données copiées dans le presse-papiers
    </v-snackbar>

    <!-- Popup d'importation de feuilles de route prévisionnelles -->
    <importer-feuilles-route-popup ref="importerPopup" @importationTerminee="rafraichirFeuillesRoute"></importer-feuilles-route-popup>

    <!-- Popup de saisie d'une note -->
    <edit-note ref="editNote" @note-modifiee="nodeModifieeEventHandler" @note-creee="nodeCreeeEventHandler"></edit-note>

    <!-- Popup de modification d'un flux-->
    <modifier-flux-popup ref="modifierFluxPopup" @fluxmodifie="fluxmodifieEventHandler"></modifier-flux-popup>

    <!-- Popup de gestion des flux prévisionnels -->
    <gestion-flux-previsionnels-popup ref="gestionFluxPrevisionnelsPopup"></gestion-flux-previsionnels-popup>

    <!-- Popup d'affichage de la légende des couleurs -->
    <v-dialog v-model="dialogLegende" persistent :overlay="false" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Légende des couleurs
        </v-card-title>
        <v-card-text>
          <table>
            <tr><td><img src="@/assets/flux_previsionnel.jpg" alt="Flux encours" style="border: 1px solid #E0E0E0"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux prévisionnel</h2></td></tr>
            <tr><td><img src="@/assets/flux_couvert.jpg" alt="Flux encours"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux couvert</h2></td></tr>
            <tr><td><img src="@/assets/flux_encours.jpg" alt="Flux encours"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux en cours</h2></td></tr>
            <tr><td><img src="@/assets/flux_ferme.jpg" alt="Flux ouvert"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux terminé</h2></td></tr>
          </table>
          <table>
            <tr><td><img src="@/assets/date_ok.jpg" alt="Date ok"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux à l'heure</h2></td></tr>
            <tr><td><img src="@/assets/date_retard_24.jpg" alt="Flux ouvert"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux avec 24h de retard</h2></td></tr>
            <tr><td><img src="@/assets/date_retard_48.jpg" alt="Flux ouvert"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux avec 48h de retard</h2></td></tr>
            <tr><td><img src="@/assets/date_retard_72.jpg" alt="Flux ouvert"/></td><td><h2 style="padding-left: 10px; text-align: left">Flux avec au moins 72h de retard</h2></td></tr>
          </table>
        </v-card-text>
        
        <v-card-actions>
          <v-btn text @click="dialogLegende = false">fermer</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Popup affichage de la source -->
    <v-dialog v-model="dialogSource" scrollable  :overlay="false" max-width="900px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Source de l'information
        </v-card-title>
        <v-card-text >
          <div>Type source : {{eflxSelectionne != undefined ? eflxSelectionne.eflxTypeSource : ""}}</div>
          <div>Source : {{eflxSelectionne != undefined ? eflxSelectionne.eflxSource : ""}}</div>
          <v-textarea v-model="evenementSource" rows="20">
          </v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Popup liste des wagons -->
    <v-dialog v-model="dialogWagons" scrollable  :overlay="false" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Wagons
          <v-spacer></v-spacer>
          <v-btn color="success" @click="copierTableauJsonEnCsv(wagons)">Copier</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headersWagon" dense :items-per-page="500" :items="wagons" hide-default-footer class="elevation-1" :loading="loading">
            <template v-slot:item.wgPresent="{ item }">
              <v-chip small :color="calculerCouleurWagonPresent(item.wgPresent)" dark>{{ item.wgPresent == 1 ? "Présent" : (item.wgPresent == 0 ? "Absent" : "-") }}</v-chip>
            </template>            
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Popup liste des PO -->
    <v-dialog v-model="dialogPo" scrollable  :overlay="false" max-width="1200px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Purchase orders
          <v-spacer></v-spacer>
          <v-btn color="success" @click="copierTableauJsonEnCsv(poliste)">Copier</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headersPo" dense :items-per-page="500" :items="poliste" hide-default-footer class="elevation-1" :loading="loading">
            <template v-slot:item.poNumero="{ item }">
              <v-btn v-if="item.poNumero != ''" :loading="caLoading" outlined color="blue" small @click="boutonChercherCodesArticles(item)">{{ item.poNumero }}</v-btn>
            </template>  
            <template v-slot:item.wgPresent="{ item }">
              <v-chip small :color="calculerCouleurWagonPresent(item.wgPresent)" dark>{{ item.wgPresent == 1 ? "Présent" : (item.wgPresent == 0 ? "Absent" : "-") }}</v-chip>
            </template>  
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Popup liste des codes articles -->
    <v-dialog v-model="dialogCa" scrollable  :overlay="false" max-width="1200px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Articles
          <v-spacer></v-spacer>
          <v-btn color="success" @click="copierTableauJsonEnCsv(caliste)">Copier</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headersCa" dense :items-per-page="500" :items="caliste" hide-default-footer class="elevation-1" :loading="loading">
            <template v-slot:item.caDateDocument="{ item }">
              {{ item.caDateDocument.substr(0, 10) }}
            </template>  
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" text @click="dialogCa = false">fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup visu des alertes infos -->
    <v-dialog v-model="dialogAi" scrollable  :overlay="false" max-width="1200px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          <h-group style="width: 100%">
            <div>Alertes infos</div>
            <v-spacer></v-spacer>
            <v-btn text color="blue" @click="ajouterAlerteInfoClickHandler" v-if="$USER_SUIVITRAINMANAGER"><v-icon left>mdi-plus</v-icon>ajouter</v-btn>
          </h-group>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headersAi" dense :items-per-page="500" :items="alertesInfos" hide-default-footer class="elevation-1" :loading="loading">
            <template v-slot:item.aiDate="{ item }">
              <div>{{ formatDate(item.aiDate) + ' ' + formatHeure(item.aiDate) }}</div>
            </template>            
            <template v-slot:item.aiMessage="{ item }">
              <div style="max-width: 500px;" class="red--text">{{ item.aiMessage }}</div>
            </template>            
            <template v-slot:item.aiDateEcheance="{ item }">
              <div>{{ formatDate(item.aiDateEcheance) }}</div>
            </template>            
            <template v-slot:item.boutons="{ item }">
              <v-btn text color="error" @click="supprimerAlerteInfo(item.aiAiId)"><v-icon left>mdi-delete</v-icon>supprimer</v-btn>
            </template>            
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogAi = false">fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup ajout d'une alerte info -->
    <v-dialog v-model="dialogAjouterAi" scrollable  :overlay="false" max-width="1200px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Ajout d'une alerte info
        </v-card-title>
        <v-card-text>
          <v-lazy>
            <v-text-field label="Message" ref="refAlerteInfoMessage" autofocus  maxlength="250"></v-text-field>
          </v-lazy>
          <h-group>
            <v-menu v-model="datepickerAiEcheanceMenu" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="datepickerAiSEcheance" label="Date d'échéance" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" style="max-width: 250px" class="mx-3"></v-text-field>
              </template>
              <v-date-picker v-model="datepickerAiEcheance" no-title @input="choixJourDateEchanceAlerteInfo"></v-date-picker>
            </v-menu>

            <v-text-field label="Priorité" type="number" class="shrink" v-model="prioriteAi"></v-text-field>
          </h-group>

        </v-card-text>
        
        <v-card-actions>
          <v-btn color="success"  @click="validerAlerteInfoClickHandler">valider</v-btn>
          <v-btn text @click="dialogAjouterAi = false">annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    
    <!-- Tablulation pour les 2 onglets de la vue -->
    <h-group style="flex-flow: nowrap;">
      <v-tabs v-model="tab" align-with-title height="20px">
        <v-tabs-slider color="yellow"></v-tabs-slider>

        <!-- Suivi des flux -->
        <v-tab @change="rafraichirFeuillesRoute">
          Suivi des flux
        </v-tab>

        <!-- Prévisionnel de livraison des PO -->
        <v-tab @change="rechercherPurchaseOrder">
          Prévisionnel de livraison
        </v-tab>
      </v-tabs>

      <v-btn x-small color="success" @click="dialogLegende = true">Légende</v-btn>

    </h-group>

    <v-tabs-items v-model="tab">

      <!--                                    -->
      <!-- Onglet "Suivi des flux"            -->
      <!--                                    -->
      <v-tab-item class="mt-2" >

        <!-- Filtres -->
        <h-group class="green lighten-5 mb-1" style="align-items: start">
          <h-group style="background-color: #931616; color: white; width: 1245px;" dark flat dense class="px-2">
            
            <!-- Filtre Nombre de jours -->
            <v-group style="width: 110px; font-size:10px">
              <h-group>
                  <div style="width: 85px">
                    <v-select class="mr-3" @input="rafraichirFeuillesRoute" :items="[-10, -20, -30, -60, -90, -180, -365, -3650]" v-model="filtreJoursAmont" dark dense hide-details></v-select>
                  </div>
                  <div class="ml-0">jours</div>
                </h-group>
                <h-group>
                  <div style="width: 85px">
                    <v-select class="mr-3" @input="rafraichirFeuillesRoute" :items="['+10', '+20', '+30', '+60', '+90', '+180', '+365', '+3650']" v-model="filtreJoursAval" dark dense hide-details></v-select>
                  </div>
                  <div class="ml-0">jours</div>
                </h-group>
            </v-group>

            <!-- Date croissante / décroissante -->
            <v-radio-group v-model="filtreTriDate" dense hide-details style="margin-top: 0;">
              <v-radio dark label="Date décroissante" dense hide-details value="1"  @click="rafraichirFeuillesRoute">
                <template v-slot:label><div style="color: white">Date décroissante</div></template>
              </v-radio>
              <v-radio dark label="Date croissante" value="2" dense hide-details @click="rafraichirFeuillesRoute">
                <template v-slot:label><div style="color: white">Date croissante</div></template>
              </v-radio>
            </v-radio-group>              
            
            <!-- Filtre sur le statut -->
            <h-group class="ml-2" style="align-items: start; width: 265px;" >
              <v-group style="align-items: start" class="mr-1">
                <v-checkbox x-small dark hide-details dense v-model="afficherFluxPrevisionnels" @click="rafraichirFeuillesRoute" >
                  <template v-slot:label><div class="filtreTexteStatut">Prév.</div></template>
                </v-checkbox>
                <v-checkbox dark hide-details dense v-model="afficherFluxAnnules" @click="rafraichirFeuillesRoute" >
                  <template v-slot:label><div class="filtreTexteStatut">Annulés</div></template>
                </v-checkbox>
              </v-group>
              <v-group style="align-items: start" class="mr-1">
                <v-checkbox dark hide-details dense v-model="afficherFluxOuverts" @click="rafraichirFeuillesRoute" >
                  <template v-slot:label><div class="filtreTexteStatut">Ouverts</div></template>
                </v-checkbox>
                <v-btn color="success" x-small class="mt-2 ml-3" @click="boutonAucunFiltreStatut">aucun</v-btn>
              </v-group>
              <v-group style="align-items: start" class="mr-1">
                <v-checkbox dark hide-details dense v-model="afficherFluxFermes" @click="rafraichirFeuillesRoute" >
                  <template v-slot:label><div class="filtreTexteStatut">Fermés</div></template>
                </v-checkbox>
                <v-btn color="success" x-small class="mt-2 ml-3" @click="boutonToutFiltreStatut">tout</v-btn>
              </v-group>
            </h-group>

            <h-group >
              <!-- Filtre sur le lieu de départ -->
              <v-text-field dark style="width: 150px" class="ml-2 mr-1" clearable name="name" label="Lieu de départ" v-model="lieuDepartRecherchee"></v-text-field>

              <!-- Bouton d'appel des trajets mémorisés-->
              <v-menu bottom :close-on-click="false" :close-on-content-click="false" v-model="menuTrajets">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text icon color="#00FF00" small><v-icon>mdi-ray-start-arrow</v-icon></v-btn>
                </template>
                <v-list dense hide-details>
                  <v-list-item dense link v-for="(trajet, index) in trajets" :key="index">
                    <v-list-item-content dense @click="rafraichirTrajet(trajet)">
                      {{ trajet }}
                    </v-list-item-content>
                    <v-list-item-action><v-btn icon text color="green" @click="descendreTrajet(trajet)"><v-icon>mdi-arrow-down-thin</v-icon></v-btn></v-list-item-action>
                    <v-list-item-action><v-btn icon text color="green" @click="monterTrajet(trajet)"><v-icon>mdi-arrow-up-thin</v-icon></v-btn></v-list-item-action>
                    <v-list-item-action><v-btn icon text color="red" @click="supprimerTrajet(trajet)"><v-icon>mdi-alpha-x</v-icon></v-btn></v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>

              <!-- Filtre sur le lieu d'arrivée -->
              <v-text-field dark style="width: 150px" clearable class="mr-3 ml-1" name="name" label="Lieu d'arrivée" v-model="lieuArriveeRecherchee"></v-text-field>

              <!-- Filtre sur la référence -->
              <v-text-field dark style="width: 150px" clearable class="mr-3" name="name" label="Référence" v-model="referenceRecherchee"></v-text-field>
            </h-group>

            <!-- Bouton Rafraichir -->
            <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
              <v-btn color="success" @click="rafraichirFeuillesRoute" v-if="$USER_SUIVITRAINMANAGER" dark small v-bind="attrs" v-on="on" :loading="loading" ><v-icon dark>mdi-refresh</v-icon></v-btn>            
            </template><span>Rafraichir la liste</span></v-tooltip>

            <!-- Bouton Importer -->
            <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
              <v-btn color="success" class="ml-2" @click="importerFeuillesRoute" v-if="$USER_SUIVITRAINMANAGER" dark small v-bind="attrs" v-on="on"><v-icon dark>mdi-import</v-icon></v-btn>            
            </template><span>Importer des flux prévisionnels</span></v-tooltip>

            <!-- Bouton gestion des flux prévisionnels -->
            <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
              <v-btn color="success" class="ml-2" @click="boutonOuvrirFluxPrevisionnelsClickEventHandler" v-if="$USER_SUIVITRAINMANAGER" dark small v-bind="attrs" v-on="on"><v-icon dark>mdi-eye</v-icon></v-btn>            
            </template><span>Voir et gérer les flux prévisionnels</span></v-tooltip>

          </h-group>

          <!-- Alerte infos -->
          <div style="display:flex; flex-direction: row; font-weight: bold; cursor: pointer; width:634px; height: 70px; overflow: hidden; align-items: center;"
            :class="'ml-2 grey lighten-4 red--text'" @click="dialogAi = true">
            <div v-if="alertesInfos.length > 0" style="flex: 1; text-align: center;" @click="dialogAi = true">{{ alertesInfos[alertesInfosIndex].aiMessage }}</div>
            <div v-if="alertesInfos.length == 0" style="color: #C0C0C0; flex: 1; text-align: center;" @click="dialogAi = true">pas d'alerte info ...</div>
            <div style="width: 0; height: 100%" @click="dialogAi = true"></div>
          </div>
        </h-group>

        <v-progress-linear v-if="loading" indeterminate color="#961616"></v-progress-linear>

        <v-group style="overflow: auto; height: 730px;" class="green lighten-5" >
          <!--                              -->
          <!-- Cadre d'une feuille de route -->
          <!--                              -->
          <v-expansion-panels class="mb-6 pa-1" multiple>
            <v-expansion-panel v-for="(fr, indexFr) in frs" :key="indexFr" class="mb-1" elevation="2" style="width: 100%" >
              
              <!-- Entête de la feuille de route -->
              <v-expansion-panel-header primary-title expand-icon="mdi-menu-down" :color="getFrColor(fr)" class="px-2 py-1">
                <template v-slot:actions>
                  <v-icon v-if="fr.nt.length > 0" color="error">mdi-alert-circle</v-icon>
                </template>
                <h-group style="width: 100%;">

                  <!-- Lieu plus date de départ -->
                  <v-group class="mr-8" style="width: 270px;">
                    <div class="titreValeur">{{getLieu(fr.frDepartLieu)}}</div>
                    <h5 class="mb-1">{{fr.frDepartLieu}}</h5>
                    <div class="titreValeur">{{formatDate(fr.frDepartDateTheorique)}}</div>
                  </v-group>

                  <!-- Date de départ -->
                  <div>
                    <div class="mb-1" style="text-align: center">Date de départ</div>
                    <div :class="'dateReelle ' + calculerClasseDateReelle(fr.frDepartDateTheorique, fr.frDepartDateReelle)">
                      <div v-if="fr.frDepartDateReelle == undefined">{{formatDate(fr.frDepartDateTheorique)}}</div>
                      <div v-if="fr.frDepartDateReelle != undefined">{{formatDate(fr.frDepartDateReelle)}} {{formatHeure(fr.frDepartDateReelle)}}</div>
                    </div>
                    <div class="motifAnnulation" v-if="fr.frStatut == $FLUX_STATUT_ANNULE">ANNULÉ : {{ fr.frMotifAnnulation }}</div>
                  </div>
                  
                  <v-switch :loading="fr.statutLoading" v-if="fr.frStatut <= $FLUX_STATUT_COUVERT" class="ml-4 mt-3" :label="`${fr.statutCouvert ? 'Couvert' : 'Non couvert'}`" v-model="fr.statutCouvert" @click="boutonFluxCouvert($event, fr, indexFr)"></v-switch>

                  <v-spacer></v-spacer>

                  <!-- Référence et boutons de visualisation des wagons et PO -->
                  <v-group style="align-items: center">
                    <div class="mb-2">{{fr.frReference}}&nbsp;&nbsp;<span style="font-size:14px">id:{{fr.frId}}</span></div>
                    <h-group class="mb-1">
                      <div class="mr-2">{{fr.po.length}}</div>
                      <v-btn text color="blue" outlined @click="lirePo(fr)">PO{{fr.po.length > 1 ? 's' : ''}}</v-btn>
                      <div class="mr-2 ml-8">{{fr.wg.length}}</div>
                      <v-btn text color="blue" outlined @click="lireWagons(fr)">wagon{{fr.wg.length > 1 ? 's' : ''}}</v-btn>
                    </h-group>
                  </v-group>                
                  
                  <v-spacer></v-spacer>
                  
                  <v-btn small outlined @click="ajouterModifierNoteClickHandler($event, undefined, fr.frId)" v-if="$USER_SUIVITRAINMANAGER"><v-icon left>mdi-plus</v-icon>note</v-btn>

                  <v-spacer></v-spacer>

                  <!-- Date d'arrivée -->
                  <div class="mr-4">
                    <div class="mb-1" style="text-align: center">Date d'arrivée</div>
                    <div :class="'dateReelle ' + calculerClasseDateReelle(fr.frArriveeDateTheorique, fr.frArriveeDateReelle)">
                      <div v-if="fr.frArriveeDateReelle == undefined">{{formatDate(fr.frArriveeDateTheorique)}}</div>
                      <div v-if="fr.frArriveeDateReelle != undefined">{{formatDate(fr.frArriveeDateReelle)}} {{formatHeure(fr.frArriveeDateReelle)}}</div>
                    </div>
                  </div>

                  <!-- Lieu plus date d'arrivée -->
                  <v-group class="mr-8" style="width: 270px;">
                    <div class="titreValeur">{{getLieu(fr.frArriveeLieu)}}</div>
                    <h5 class="mb-3">{{fr.frArriveeLieu}}</h5>
                    <div class="titreValeur">{{formatDate(fr.frArriveeDateTheorique)}}</div>
                  </v-group>
                </h-group>
              </v-expansion-panel-header>

              <!-- Liste des trains de la feuille de route -->
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Prestataire</th>
                        <th>Trajet</th>
                        <th><div style="display: inline" class="mr-2"><v-icon small>mdi-arrow-expand-right</v-icon></div>Départ théor./réel</th>
                        <th>Arrivée théor./réel<div style="display: inline" class="ml-2"><v-icon small>mdi-arrow-collapse-right</v-icon></div></th>
                        <th>Contrôle</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="train in fr.tr" :key="train.trId">
                        <td>{{train.trId}}</td>
                        <td>{{train.trPrestataire}}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{train.trDepartLieu}}-{{train.trArriveeLieu}}</div>
                            </template>
                            <span>{{lireTrajet(train.trDepartLieu, train.trArriveeLieu)}}</span>
                          </v-tooltip>
                        </td>

                        <!-- Date/heure de départ théorique et réelle -->
                        <td>
                          <h-group>
                            <div class="ml-2 mr-2">Prévu<br/>Réel</div>
                            <div style="padding: 3px;min-width: 250px;" :class="calculerClasseDateReelle(train.trDepartDateTheorique, train.trDepartDateReelle, 4, 8)">{{formatDateHeure(train.trDepartDateTheorique)}}<br/>{{formatDateHeure(train.trDepartDateReelle)}}</div>
                          </h-group>
                        </td>

                        <!-- Date/heure d'arrivée théorique et réelle -->
                        <td>
                          <h-group>
                            <div class="ml-2 mr-2">Prévu<br/>Réel</div>
                            <div style="padding: 3px;min-width: 250px;" :class="calculerClasseDateReelle(train.trArriveeDateTheorique, train.trArriveeDateReelle, 4, 8)">{{formatDateHeure(train.trArriveeDateTheorique)}}<br/>{{formatDateHeure(train.trArriveeDateReelle)}}</div>
                          </h-group>
                        </td>

                        <!-- Contrôle wagons -->
                        <td>
                          <div >
                            <v-btn text :color="calculerCouleurControleWagons(train.trControleWagonsOk)" @click="lireWagons(fr)">
                              <v-icon left :color="calculerCouleurControleWagons(train.trControleWagonsOk)">mdi-train-car-container</v-icon>
                              wagons
                            </v-btn>
                          </div>
                        </td>

                        <td>{{train.trNote}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- Liste des notes de la feuille de route -->
                <div v-for="nt in fr.nt" :key="nt.ntId">
                  <h6 style="margin-bottom: 0px; text-align: left">Créée le {{formatDateHeure(nt.ntDateCreation)}} <span v-if="nt.ntDateModification != null"> - modifiée le {{formatDateHeure(nt.ntDateModification)}}</span> <span>par {{nt.ntAuteur}}</span></h6>
                  <h-group>
                    <div v-html="nt.ntNote" class="note"></div>
                    <v-btn color="success" fab x-small dark class="ma-2" @click="ajouterModifierNoteClickHandler($event, nt, nt.ntFrId)" v-if="$USER_SUIVITRAINMANAGER"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn color="error" fab x-small dark class="ma-2" @click="supprimerNoteClickHandler(nt.ntId)" v-if="$USER_SUIVITRAINMANAGER"><v-icon>mdi-delete</v-icon></v-btn>
                  </h-group>
                </div>
                <v-btn text @click="ajouterModifierNoteClickHandler($event, undefined, fr.frId)" v-if="$USER_SUIVITRAINMANAGER"><v-icon left>mdi-plus</v-icon>ajouter une note</v-btn>

                <v-btn class="ml-10" text @click="fermerFeuilleRouteClickHandler(fr)" v-if="$USER_SUIVITRAINMANAGER"><v-icon left color="red">mdi-close</v-icon>modifier ce flux</v-btn>

              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-group>
      </v-tab-item>

      <!--                                    -->
      <!-- Onglet "Prévisionnel de livraison des PO            -->
      <!--                                    -->
      <v-tab-item class="mt-2">
        <!-- Filtres -->
        <v-toolbar color="cyan" dark flat>
          <v-toolbar-title>Purchase order</v-toolbar-title>


          <template v-slot:extension>
            <div style="display: inherit;">

              <!-- Date de début -->
              <v-menu v-model="menuDateDebut" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">            
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="filtreDateDebut" label="Date de début" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="filtreDateDebut" @input="menuDateDebut = false"/>
              </v-menu>

              <!-- Date de fin -->
              <v-menu v-model="menuDateFin" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">            
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="filtreDateFin" label="Date de fin" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="filtreDateFin" @input="menuDateFin = false"/>
              </v-menu>
              
              <!-- Produit -->
              <v-text-field v-model="filtreProduit" class="mx-2" clear-icon="mdi-close-circle" clearable label="Produit" style="width: 200px"></v-text-field>

              <!-- PO -->
              <v-text-field v-model="filtrePO" class="mx-2" clear-icon="mdi-close-circle" clearable label="PO"></v-text-field>

              <!-- Wagons vides ? -->
              <v-checkbox label="Inclure wagons vides" class="mx-2" v-model="poInclureWagonsVides"></v-checkbox>

              <v-btn color="success" class="ml-2 mr-5" @click="boutonRechercherPurchaseOrderClick" :loading="loading">rafraichir</v-btn>
              <v-btn color="success" @click="boutonExporterPurchaseOrderClick" :loading="loading">exporter</v-btn>
            </div>
          </template>
        </v-toolbar>

        <!-- Liste des purchase orders par date de livraison -->
        <v-card elevation="5" class="grey lighten-5 my-2">
          <v-card-text elevation-10>
            <v-text-field v-model="poSearch" clear-icon="mdi-close-circle" clearable append-icon="mdi-magnify" label="Rechercher dans la liste" class="mx-4" style="width: 500px" single-line hide-details></v-text-field>
            <v-data-table dense :loading="loading" :headers="headersPurchaseOrders" hide-details :items="purchaseOrders" item-key="index" height="520px" fixed-header
                multi-sort :sort-by="['frArriveeDateTheorique']" :sort-desc="true" class="elevation-1 mt-3" :search="poSearch" :items-per-page="10000">
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

  </div>  
</template>


<script>
import axios from 'axios';
import HGroup from '@/components/flex/HGroup.vue';
import EditNote from '@/views/suivitrains/EditNote.vue'
import Vue from 'vue'
import { json2excel } from 'js2excel';
import ImporterFeuillesRoutePopup from '@/components/popup/ImporterFeuillesRoutePopup.vue'
import ModifierFluxPopup from '@/components/popup/ModifierFluxPopup.vue'
import GestionFluxPrevisionnelsPopup from '@/components/popup/GestionFluxPrevisionnelsPopup.vue'

export default {
  components: {HGroup, EditNote,ImporterFeuillesRoutePopup, ModifierFluxPopup, GestionFluxPrevisionnelsPopup },

  data() {
    return {
      tab: 0,
      loading: false,
      snackErreur: false,
      snackMessage: "",
      fluxes: [],
      snackClipbaord: false,
      menuTrajets: false,

      poSearch: "",
      headersPurchaseOrders: [
         { text: 'Produit', value: 'poProduit' },
         { text: 'PO', value: 'poNumero' },
         { text: 'Article', value: 'caCodeArticle' },
         { text: 'Description', value: 'caDescription' },
         { text: 'Nb palettes', value: 'poNbPalettes' },
         { text: 'Poids', value: 'poPoids' },
         { text: 'Wagon', value: 'poNumeroWagon' },
         { text: 'Note', value: 'poNote' },
         { text: 'Lieu chargement', value: 'poLieuChargement' },
         { text: 'Départ', value: 'frDepartLieu' },
         { text: 'Prévu', value: 'frDepartDateTheorique' },
         { text: 'réel', value: 'frDepartDateReelle' },
         { text: 'Arrivée', value: 'frArriveeLieu' },
         { text: 'Prévu', value: 'frArriveeDateTheorique' },
         { text: 'Réel', value: 'frArriveeDateReelle' },
         { text: 'Référence', value: 'frReference' }
      ],

      frs: [],
      purchaseOrders:[],
      filtreJoursAmont: 10,
      filtreJoursAval: '+10',
      headersEvenements: [
         { text: 'Date', value: 'eflxDate' },
         { text: 'Trajet', value: 'eflxLieu' },
         { text: 'Type', value: 'eflxType' },
         { text: 'Niveau', value: 'eflxNiveauAlerte' },
         { text: 'Info. prestataire', value: 'eflxInfoPrestataire' },
         { text: 'Info. flux', value: 'eflxInfoFlux' }
      ],
      dialogPo: false,
      headersPo: [
         { text: 'Produit', value: 'poProduit' },
         { text: 'PO', value: 'poNumero' },
         { text: 'Nb palettes', value: 'poNbPalettes' },
         { text: 'Poids', value: 'poPoids' },
         { text: 'Lieu de chargement', value: 'poLieuChargement' },
         { text: 'Wagon', value: 'poNumeroWagon' },
         { text: '', value: 'wgPresent' },
         { text: 'Note', value: 'poNote' }
      ],
      poliste: [],
      dialogCa: false,
      headersCa: [
        { text: 'PO', value: 'caPoNumero' },
        { text: 'Code article', value: 'caCodeArticle' },
        { text: 'Date document', value: 'caDateDocument' },
        { text: 'Quantité', value: 'caQuantite' },
        { text: 'Unité', value: 'caUnite' },
        { text: 'Description', value: 'caDescription' }
      ],
      caliste: [],
      caLoading: false,

      headersWagon: [
         { text: 'Numéro', value: 'wgNumero' },
         { text: '', value: 'wgPresent'}
      ],
      dialogWagons: false,
      wagons: [],

      dialogSource: false,
      eflxSelectionne: undefined,
      evenementSource: "",

      referenceRecherchee: "",
      lieuDepartRecherchee: "",
      lieuArriveeRecherchee: "",

      filtreTriDate: '2',
      dialogLegende: false,

      filtreDateDebut: new Date().toISOString().substr(0,10),
      menuDateDebut: false,
      filtreDateFin: new Date().toISOString().substr(0,10),
      menuDateFin: false,
      filtreProduit: "",
      filtrePO: "",
      filtreLieuDepart: "",
      filtreLieuArrivee: "",

      afficherFluxPrevisionnels: true,
      afficherFluxOuverts: true,
      afficherFluxFermes: false,
      afficherFluxAnnules: true,
      dialogFermetureFlux: false,
      fermerFeuilleRouteDateArrivee: new Date().toISOString().substr(0, 10),
      menuDateArrivee: false,

      dialogSuppressionFlux: false,
      
      alertesInfos: [],
      alertesInfosIndex: 0,
      dialogAi: false,
      prioriteAi: 0,
      headersAi: [
        { text: 'Date', value: 'aiDate' },
        { text: 'Auteur', value: 'aiAuteur'},
        { text: 'Priorité', value: 'aiPriorite'},
        { text: 'Message', value: 'aiMessage'},
        { text: 'Echéance', value: 'aiDateEcheance'},
        { text: '', value: 'boutons'},
      ],
      dialogAjouterAi: false,
      alerteInfoMessage: "",
      datepickerAiEcheanceMenu: false,
      datepickerAiSEcheance: "",
      datepickerAiEcheance: "",

      trajets: [],
      gestionFluxPrevisionnelsPopup: false,
      poInclureWagonsVides: false

    }
  },

  created() {
    this.lireMotifsAnnulation()
  },

  mounted() {
    this.chargerFiltres()
    // Chargement des trajets mémorisés
    var t = localStorage.getItem("trajets");
    if ( (t == undefined) || (t == null) || (t == "")) {
      t = this.$user.user.trajets
    }
    if ( (t != undefined) && (t != null) && (t != "")) {
      this.trajets = JSON.parse(t)
    }
    var now = new Date();
    this.filtreDateFin = new Date(now.getTime() + 30 * 86400 * 1000).toISOString().substr(0, 10)
    this.filtreDateDebut = new Date(now.getTime() - 10 * 86400 * 1000).toISOString().substr(0, 10)
    this.lireLieus()

    setInterval(function() {
      this.alertesInfosIndex++
      if (this.alertesInfosIndex >= this.alertesInfos.length) {
        this.alertesInfosIndex = 0
      }
    }.bind(this), 4000)
  },

    methods: {
      getIntItem(name, defaut) {
      const v = localStorage.getItem(name)
      if (v != null) {
        const vi = parseInt(v)
        if (!isNaN(vi)) {
          return vi
        }
      }
      return defaut
    },

    getStringItem(name, defaut) {
      const v = localStorage.getItem(name)
      if (v != null) {
        return v
      }
      return defaut
    },

    getBooleanItem(name, defaut) {
      const v = localStorage.getItem(name)
      if (v != null) {
        const vb = v.toLocaleLowerCase() == "true"
        return vb
      }
      return defaut
    },

    chargerFiltres() {
      this.filtreJoursAmont = this.getIntItem("filtreJoursAmont", -10)
      this.filtreJoursAval = this.getStringItem("filtreJoursAval", '+10')
      this.filtreTriDate = this.getStringItem("filtreTriDate", '2')
      this.afficherFluxPrevisionnels = this.getBooleanItem("afficherFluxPrevisionnels", false)
      this.afficherFluxOuverts = this.getBooleanItem("afficherFluxOuverts", false)
      this.afficherFluxFermes = this.getBooleanItem("afficherFluxFermes", false)
      this.afficherFluxAnnules = this.getBooleanItem("afficherFluxAnnules", false)
    },

    enregistrerFiltres() {
      localStorage.setItem("filtreJoursAmont", this.filtreJoursAmont)
      localStorage.setItem("filtreJoursAval", this.filtreJoursAval)
      localStorage.setItem("filtreTriDate", this.filtreTriDate)
      localStorage.setItem("afficherFluxPrevisionnels", this.afficherFluxPrevisionnels.toString())
      localStorage.setItem("afficherFluxOuverts", this.afficherFluxOuverts.toString())
      localStorage.setItem("afficherFluxFermes", this.afficherFluxFermes.toString())
      localStorage.setItem("afficherFluxAnnules", this.afficherFluxAnnules.toString())

    },

    lireLieus() {
      var url = "/api/liLieus?access_token=" + this.$user.id;
      axios.get(url)
        .then(function(response) {
          this.lieus = response.data
          this.rafraichirFeuillesRoute();
        }.bind(this))
    },

    lireMotifsAnnulation() {
      var url = "/api/maMotifAnnulations"
      axios.get(url)
        .then(function(response) {
          this.motifsAnnulation = response.data
        }.bind(this))
    },

    getLieu(code) {
      const lieu = this.lieus.find(l => l.liCode == code)
      return lieu ? lieu.liDesignation : "?"
    },

    rechercherPurchaseOrder() {
      this.loading = true;
      var query = "dateDebut=" + this.filtreDateDebut.substr(0, 10)
      query += "&dateFin=" + this.filtreDateFin.substr(0, 10)
      query += "&inclureWagonsVides=" + this.poInclureWagonsVides
      if ((this.filtreProduit != "") && (this.filtreProduit != null)) {
        query += "&produit=" + this.filtreProduit
      }
      if ((this.filtrePO != "") && (this.filtrePO != null)) {
        query += "&numero=" + this.filtrePo
      }
      axios.get("/api/caCodeArticles/rechercherPurchaseOrder?" + query)
      //axios.get("/api/poPurchaseOrders?filter=" + encodeURIComponent(JSON.stringify(filter)) + "&access_token=" + this.$user.id)
      .then(function (response) {
        response.data.forEach(data => {
          data.frDepartDateTheorique = this.formatDateISO(data.frDepartDateTheorique)
          data.frDepartDateReelle = this.formatDateISO(data.frDepartDateReelle)
          data.frArriveeDateTheorique = this.formatDateISO(data.frArriveeDateTheorique)
          data.frArriveeDateReelle = this.formatDateISO(data.frArriveeDateReelle)
        })
        this.purchaseOrders = response.data
        this.loading = false;
      }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          this.purchaseOrders = [];
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
          this.purchaseOrders = [];
        }
      });
    
    },

    afficherErreur(message) {
      this.snackMessage = message;
      this.snackErreur = true
      this.loading = false;
    },

    boutonRechercherPurchaseOrderClick() {
      this.rechercherPurchaseOrder();
    },

    boutonExporterPurchaseOrderClick() {
      try {
        json2excel({
            data: this.purchaseOrders,
            name: 'purchase-orders',
            formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
          console.error('export error');
      }
    },

    getColor(niveau) {
      switch(niveau) {
        case 0: return 'green';
        case 1: return 'orange';
        case 2: return 'red';
      }
      return 'grey'
    },

    /**
     * Couleur du cadre de la feuille de route = f(statut)
     * @param {Object} fr 
     */
    getFrColor(fr) {
      //console.log(fr)
      switch(fr.frStatut) {
        case 0: return "white"              // Prévisionnel
        case 1: return 'blue lighten-3'     // Couvert
        case 2: return 'yellow lighten-3'   // Ouvert
        case 3: return 'green lighten-3'    // Fermé ok
        case 4: return 'red'                // Annulé
      }
      return 'orange'
    },

    async rafraichirFeuillesRoute() 
    {
      this.enregistrerFiltres();

      this.loading = true;
      const now = new Date()
      var dAmont = new Date(now.getTime() + (this.filtreJoursAmont * 24 * 3600 * 1000))
      var sdAmont = dAmont.toISOString().substr(0, 10);
      var dAval = new Date(now.getTime() + (parseInt(this.filtreJoursAval) * 24 * 3600 * 1000))
      var sdAval = dAval.toISOString().substr(0, 10);
      var filter = {
        "where": {
          "and": [
            {
              "frDepartDateTheorique": {
                "gt": sdAmont
              }
            },
            {
              "frArriveeDateTheorique": {
                "lt": sdAval
              }
            }
          ]
        },
        "include": [
          "po", 
          "tr", 
          "wg",
          {"relation": "nt", "scope": {"order": "ntDateCreation DESC"}}
        ],
        "order": ["frDepartDateTheorique " + (this.filtreTriDate == '1' ? "DESC" : "ASC"), "frArriveeDateTheorique " + (this.filtreTriDate == '1' ? "DESC" : "ASC")]
      }
      if (this.referenceRecherchee != "") {
        filter.where.and.push({
          "frReference": {
            "like": "%" + this.referenceRecherchee + "%"
          }
        })
      }
      var filtreStatut = []
      if (this.afficherFluxPrevisionnels) {
        filtreStatut.push({"frStatut": this.$FLUX_STATUT_PREVISIONNEL})
        filtreStatut.push({"frStatut": this.$FLUX_STATUT_COUVERT})
      }
      if (this.afficherFluxOuverts) { 
        filtreStatut.push({"frStatut": this.$FLUX_STATUT_OUVERT})
      }
      if (this.afficherFluxFermes) {
        filtreStatut.push({"frStatut": this.$FLUX_STATUT_FERME})
      }
      if (this.afficherFluxAnnules) {
        filtreStatut.push({"frStatut": this.$FLUX_STATUT_ANNULE})
      }
      if (filtreStatut.length > 0) {
        filter.where.and.push({"or": filtreStatut})
      }
      else {
        filter.where.and.push({"frStatut": -1})
      }

      const lieuDepartRecherchee = this.getField(this.lieuDepartRecherchee)
      if (lieuDepartRecherchee != "") {
        var liste = lieuDepartRecherchee.split(" ")
        if (liste.length == 1) {
          // Un seul lieu de départ
          filter.where.and.push({
            "frDepartLieu": {
            "like": "%" + lieuDepartRecherchee.toUpperCase() + "%"
            }
          })
        }
        else {
          // Plusieurs lieus de départ
          var filtreOu = []
          liste.forEach(lieu => filtreOu.push({"frDepartLieu": {"like": "%" + lieu.toUpperCase() + "%"}}))
          var filtreLieus = {
            "or": filtreOu
          }
          filter.where.and.push(filtreLieus);
        }
      }

      const lieuArriveeRecherchee = this.getField(this.lieuArriveeRecherchee)
      if (lieuArriveeRecherchee != "") {
        liste = lieuArriveeRecherchee.split(" ")
        if (liste.length == 1) {
          // Un seul lieu de départ
          filter.where.and.push({
            "frArriveeLieu": {
            "like": "%" + lieuArriveeRecherchee.toUpperCase() + "%"
            }
          })
        }
        else {
          // Plusieurs lieus de départ
          filtreOu = []
          liste.forEach(lieu => filtreOu.push({"frArriveeLieu": {"like": "%" + lieu.toUpperCase() + "%"}}))
          filtreLieus = {
            "or": filtreOu
          }
          filter.where.and.push(filtreLieus);
        }
      }

      // Mémorisation éventuel du trajet
      if ((lieuDepartRecherchee != "") || (lieuArriveeRecherchee != "")) {
        const trajet = lieuDepartRecherchee  + " -> " + lieuArriveeRecherchee
        if (this.trajets.indexOf(trajet) < 0) {
          this.trajets.push(trajet)
          localStorage.setItem("trajets", JSON.stringify(this.trajets))
          this.sauvegarderTrajetsBdd()
        }
      }

      await axios.get("/api/frFeuilleRoutes?filter=" + encodeURIComponent(JSON.stringify(filter)) + "&access_token=" + this.$user.id)
      .then(function (response) {
        response.data.forEach(function(fr) { 
          fr.statutCouvert = fr.frStatut == this.$FLUX_STATUT_COUVERT
          fr.statutLoading = false
        }.bind(this))
        this.frs = response.data
        this.loading = false;
      }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          this.flux = [];
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
          this.flux = [];
        }
      });

      this.lireAlertesInfos();

    },

    getField(value) {
      if ((value == null) || (value == undefined)) {
        return ""
      }
      if (value.trim) {
        return value.trim()
      }
      return ""
    },
    
    async lireAlertesInfos() {
      // Rafraichissement des alertes infos
      const tri = {"order": ["aiPriorite DESC", "aiDateEcheance DESC"]}
      axios.get("/api/aiAlerteInfos?filter=" + JSON.stringify(tri))
      .then(function (response) {
        this.alertesInfos = response.data
        //console.log("this.alertesInfos", this.alertesInfos)
      }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          this.alertesInfos = [];
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
          this.alertesInfos = [];
        }
      });       
    },

    async supprimerAlerteInfo(aiAiId) {
      // Suppression de l'alerte info
      axios.delete("/api/aiAlerteInfos/" + aiAiId)
      .then(function () {
        this.lireAlertesInfos();
      }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
        }
      });       
    },

    async lirePo(flux) 
    {
      // Mise à jour par rapport à la présence des wagons de chaque PO
      const liste = flux.po
      liste.forEach(po => {
        const wg = flux.wg.find(w => w.wgNumero == po.poNumeroWagon)
        po.wgPresent = wg ? wg.wgPresent : undefined
      })
      this.poliste = liste;
      this.dialogPo = true;
    },

    async lireWagons(fr) 
    {
      this.wagons = fr.wg
      this.dialogWagons = true;
    },

    formatDateISO(date) {
      if (date == undefined) {
        return ""
      }
      return date.substr(0,10).replace(/-/g,'/')
      //const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      //const event = new Date(date)
      //return this.moment(new Date(date), 'YYYY-MM-DD')
      //return event.toLocaleDateString('fr-Fr', options)
    },

    formatDate(date) {
      if (date == undefined) {
        return ""
      }
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const event = new Date(date)
      return event.toLocaleDateString('fr-Fr', options)
    },

    formatDateHeure(date) {
      if (date == undefined) {
        return ""
      }
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const event = new Date(date)
      return event.toLocaleDateString('fr-Fr', options) + " " + event.toLocaleTimeString()
    },

    formatHeure(date) {
      if (date == undefined) {
        return ""
      }
      const event = new Date(date)
      return event.toLocaleTimeString()
    },

    lienAfficheurSourceEvenement(trajet, eflx) {
      this.eflxSelectionne = eflx;
      axios.get("/api/eflxEvenementFluxes/lireSource?prestataire=" + trajet.ftPrestataire + "&source=" + eflx.eflxSource + "&access_token=" + this.$user.id)
        .then(function(response) {
          this.evenementSource = response.data
          console.log("evenementSource", this.evenementSource)
        }.bind(this))
      this.dialogSource = true;
    },

    copierTableauJsonEnCsv(tableau) {
      var fields = Object.keys(tableau[0])
      var replacer = function(key, value) { return value === null ? '' : value } 
      var csv = tableau.map(function(row){
        return fields.map(function(fieldName){
          return JSON.stringify(row[fieldName], replacer)
        }).join('\t')
      })
      csv.unshift(fields.join('\t')) // add header column
      csv = csv.join('\r\n');
      navigator.clipboard.writeText(csv);
      //this.copyToClipBoard(csv);
      //window.prompt("Appuyer sur Ctrl-C pour copier dans le presse-papier puis cliquer sur Ok", csv);
    },

    boutonVoirLeFluxClick(flux) {
      this.referenceRecherchee = flux.flxReference;
      this.tab = 0
      this.rafraichirFeuillesRoute()
    },

    copyToClipBoard(text) {
        var input = document.createElement('textarea');
        input.innerHTML = text;
        document.body.appendChild(input);
        input.select();
        console.log("*",text)
        setTimeout(function() {
          document.execCommand('copy');
          console.log("B")
          document.body.removeChild(input);
          this.snackClipbaord = true;

        }.bind(this), 250)
    },

    calculerCouleurWagonPresent(wgPresent) {
      switch(wgPresent) {
        case 0: return 'red'
        case 1: return 'green'
      }
      return "#C0C0C0"
    },

    calculerCouleurControleWagons(controleWagonsOk) {
      switch(controleWagonsOk) {
        case 0: return 'red'
        case 1: return 'green'
      }
      return "#C0C0C0"
    },

    calculerClasseDateReelle(dateTheorique, dateReelle, dureeAlerte1 = 24, dureeAlerte2 = 48, dureeAlerte3 = 72) {
      if ((dateTheorique == undefined) || (dateReelle == undefined)) {
        return 'dateReelleIndefinie'
      }
      try {
        const d1 = new Date(dateTheorique)
        const d2 = new Date(dateReelle)
        // Alerte 1
        const dureeAlerte1ms = dureeAlerte1 * 3600 * 1000
        const dureeAlerte2ms = dureeAlerte2 * 3600 * 1000
        const dureeAlerte3ms = dureeAlerte3 * 3600 * 1000
        if (d2.getTime() > (d1.getTime() + dureeAlerte3ms)) {
          return 'dateReelleAlerte3'
        }
        if (d2.getTime() > (d1.getTime() + dureeAlerte2ms)) {
          return 'dateReelleAlerte2'
        }
        if (d2.getTime() > (d1.getTime() + dureeAlerte1ms)) {
          return 'dateReelleAlerte1'
        }
          return 'dateReelleOk'
      }
      catch(error) {
        console.log("error", error)
        'dateReelleIndefinie'
      }
    },

    ajouterModifierNoteClickHandler(event, nt, frId) {
      this.$refs.editNote.ouvrir(nt, frId)
      event.stopPropagation()
    },

    nodeCreeeEventHandler(event) {
      const fr = this.frs.find(f => f.frId == event.ntFrId)
      fr.nt.push(event)
      //this.rafraichirFeuillesRoute()
    },
    
    nodeModifieeEventHandler(event) {
      const fr = this.frs.find(f => f.frId == event.ntFrId)
      if (fr) {
        const nt = fr.nt.find(n => n.ntId == event.ntId)
        if (nt) {
          const idxNt = fr.nt.indexOf(nt)
          fr.nt[idxNt] = event
          nt.node = event.ntNote;
          const idxFr = this.frs.indexOf(fr)
          Vue.set(this.frs, idxFr, fr )
        }
      }
      //this.rafraichirFeuillesRoute()
    },

    supprimerNoteClickHandler(ntId) {
      if (confirm("Supprimer la note ?")) {
        axios.delete("/api/ntNotes/" + ntId)
          .then(function() {
            this.rafraichirFeuillesRoute()            
          }.bind(this))      
      }
    },

    lireTrajet(depart, arrivee) {
      let trajet = ""
      const ldepart = this.lieus.find(l => l.liCode == depart)
      if (ldepart) {
        trajet =  ldepart.liDesignation
      }
      let larrivee = this.lieus.find(l => l.liCode == arrivee)
      if (larrivee) {
        trajet += " - "  + larrivee.liDesignation
      }
      return trajet;
    },

    importerFeuillesRoute() {
      this.$refs.importerPopup.open()
    },

    fermerFeuilleRouteClickHandler(fr) {
      this.fermerFeuilleRouteDateArrivee = fr.frArriveeDateTheorique.substr(0, 10)
      this.$refs.modifierFluxPopup.motifsAnnulation = this.motifsAnnulation
      this.$refs.modifierFluxPopup.motifAnnulation = ""
      this.$refs.modifierFluxPopup.open(fr)
      //this.dialogFermetureFlux = true
    },

    
    computeNbAlertesInfos(alertesInfos) {
      if ((alertesInfos == undefined) || (alertesInfos.length == 0)) {
        return "Alerte info"
      }
      return alertesInfos.length == 1 ? "Alerte info" : alertesInfos.length.toString() + " alertes infos"
    },

    ajouterAlerteInfoClickHandler() {
      this.dialogAjouterAi = true;
      const now = new Date();
      const echeance = new Date(now.getTime() + 3 * 86400 * 1000)
      this.datepickerAiSEcheance = echeance.toISOString().substr(0, 10)
      this.datepickerAiEcheance = echeance.toISOString().substr(0, 10)
      setTimeout(function() {
        this.$refs.refAlerteInfoMessage.$el.querySelector("input").value = ""
        this.$refs.refAlerteInfoMessage.focus();
      }.bind(this), 200)
    },

    validerAlerteInfoClickHandler() {
      const texteAlerte = this.$refs.refAlerteInfoMessage.$el.querySelector("input").value
      if (texteAlerte.trim() == "") {
        alert("Le message de l'alerte ne peut pas être vide")
        return;
      }
      // Ajout de l'alerte info
      let body = {
        aiMessage: texteAlerte,
        aiAuteur: this.$user.user.username,
        aiDateEcheance: this.datepickerAiEcheance,
        aiPriorite: this.prioriteAi
      }
      axios.post("/api/aiAlerteInfos", body)
      .then(function () {
        this.lireAlertesInfos();
        this.dialogAjouterAi = false;
      }.bind(this))
      .catch(error => {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
        }
      });       
    }, 

    choixJourDateEchanceAlerteInfo() {
      this.datepickerAiEcheanceMenu = false
      this.datepickerAiSEcheance = this.parseDate(this.datepickerAiEcheance);
    },

    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    test1(e) {
      console.log(e)
    },

    rafraichirTrajet(trajet) {
      this.menuTrajets = false
      if (trajet != "") {
        const f = trajet.split("->")
        this.lieuDepartRecherchee = f[0].trim().toUpperCase()
        if (f.length > 1) {
          this.lieuArriveeRecherchee = f[1].trim().toUpperCase()          
        }
        else {
          this.lieuArriveeRecherchee = ""
        }
        this.rafraichirFeuillesRoute()
      }
    },

    supprimerTrajet(trajet) {
      const idx = this.trajets.indexOf(trajet)
      if (idx >= 0) {
        this.trajets.splice(idx, 1)
        localStorage.setItem("trajets", JSON.stringify(this.trajets))
        this.sauvegarderTrajetsBdd()
      }
    },

    monterTrajet(trajet) {
      const idx = this.trajets.indexOf(trajet)
      if (idx > 0) {
        const temp = this.trajets[idx]
        this.trajets[idx] = this.trajets[idx - 1]
        this.trajets[idx - 1] = temp
        localStorage.setItem("trajets", JSON.stringify(this.trajets))
        this.sauvegarderTrajetsBdd()
        this.trajets = JSON.parse(JSON.stringify(this.trajets))
      }
    },

    descendreTrajet(trajet) {
      const idx = this.trajets.indexOf(trajet)
      if (idx < this.trajets.length - 1) {
        const temp = this.trajets[idx]
        this.trajets[idx] = this.trajets[idx + 1]
        this.trajets[idx + 1] = temp
        localStorage.setItem("trajets", JSON.stringify(this.trajets))
        this.sauvegarderTrajetsBdd()
        this.trajets = JSON.parse(JSON.stringify(this.trajets))
      }
    },

    /**
     * Sauvegarder les filtres de trajets dans la base de données centrale
     */
    sauvegarderTrajetsBdd() {
      const url = "/api/wdUsers/" +this.$user.userId + "?access_token=" + this.$user.id
      const body = {"trajets": JSON.stringify(this.trajets)}
      axios.patch(url, body)
        .then(function(response) {
          if (this.$user.upId == undefined) {
            this.$user.upId = response.upId
          }
        }.bind(this))
    },

    /**
     * Evènement déclenché par le popup de modification d'un flux
     */
    fluxmodifieEventHandler() {
      this.rafraichirFeuillesRoute()
    },

    boutonOuvrirFluxPrevisionnelsClickEventHandler() {
      this.$refs.gestionFluxPrevisionnelsPopup.open();
    },

    boutonChercherCodesArticles(po) {
      const filter = {
        "where": {
          "caPoNumero": po.poNumero
        }
      }
      this.caLoading = true;
      const url = "/api/caCodeArticles?access_token=" + this.$user.id + "&filter=" + JSON.stringify(filter);
      axios.get(url)
        .then(function(response) {
          this.caLoading = false;
          this.caliste = response.data
          this.dialogCa = true
        }.bind(this))
        .catch(function() {
          this.caLoading = false;
        }.bind(this))
    },

    boutonToutFiltreStatut() {
      this.afficherFluxPrevisionnels = true
      this.afficherFluxOuverts = true
      this.afficherFluxFermes = true
      this.afficherFluxAnnules = true
      this.rafraichirFeuillesRoute()
    },

    boutonAucunFiltreStatut() {
      this.afficherFluxPrevisionnels = false
      this.afficherFluxOuverts = false
      this.afficherFluxFermes = false
      this.afficherFluxAnnules = false
    },

    boutonFluxCouvert(event, fr, indexFr) {
      event.stopPropagation()
      fr.statutLoading = true
      console.log("bouton", event, fr.statutCouvert)
      const url = "/api/frFeuilleRoutes/" + fr.frId + "?access_token=" + this.$user.id;
      const body = {
        frStatut: fr.statutCouvert ? this.$FLUX_STATUT_COUVERT : this.$FLUX_STATUT_PREVISIONNEL
      }
      axios.patch(url, body)
        .then(function() {
          fr.statutLoading = false
          fr.frStatut = fr.statutCouvert ? this.$FLUX_STATUT_COUVERT : this.$FLUX_STATUT_PREVISIONNEL
          fr.frStatutCouvert = fr.frStatut == this.$FLUX_STATUT_COUVERT
          Vue.set(this.frs, indexFr, fr)
        }.bind(this))
        .catch(error => {
          fr.statutLoading = false
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.afficherErreur('Accès refusé')
            //this.$router.push('/login?to=/containers')
          }
          else {
            this.afficherErreur(error.message + " : " + error.response.statusText)
          }
        })


    }


  }
}
</script>

<style scoped>

.v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0px !important;
}

.filtreTexteStatut {
  font-size: 14px;
  color: white;
}
.motifAnnulation {
  color: white;
  padding: 5px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-field input{
  color: white !important;
}
.v-data-table >>> .v-data-table__wrapper > table {
  width: auto !important;
  border-collapse:separate;
  border-spacing:0 5px;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th,td {
  text-align: center !important;
}


.titreValeur {
  color: #0c4878;
  font-size: 16px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}

.dateReelle {
  border: 1px solid lightgrey;
  padding: 5px;
  /*min-height: 50px;*/
  width: 320px;
  text-align: center;
  font-size: 18px;
}

.dateReelleOk {
  color: black;
  background-color: #00FF00;
}

.dateReelleIndefinie {
  color: black;
  background-color: lightgrey;
}

.dateReelleAlerte1 {
  color: black;
  background-color: yellow;
}

.dateReelleAlerte2 {
  color: black;
  background-color: orange;
}

.dateReelleAlerte3 {
  color: white;
  background-color: red;
}

.note {
  border: 1px solid #C0C0C0;
  flex: 1;
  padding: 5px;
}

.blink_me {
  animation: blinker 5s step-start infinite;
  background-color: red !important;
  color: white;
}

@keyframes blinker {
  10% {
    opacity: 0;
  }
}
</style>